@import "styles/variables.scss";
.tokens {
  display: inline-block;
}

.token {
  display: inline-block;
}

.blockToken {
  display: block;
}
