@import "styles/variables.scss";
.languageToggle {
  display: inline-block;
  vertical-align: middle;
  background: none;
  border: none;
  min-height: 24px;
  font-weight: 600;
  font-size: $font-size-normal;
  color: var(--color-primary);
  text-decoration: underline;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color $animation-time;

  &:hover,
  &:focus {
    background-color: var(--color-neutral-x-light);
  }
}
