@import "styles/variables.scss";
.content {
  position: relative;
  width: 500px;
  max-width: $max-content-width;
  box-sizing: border-box;
  margin: 2rem auto;
  padding: 2rem;
  border: solid 2px var(--color-neutral-x-light);
  box-shadow: 2px 2px 31px 0 rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  background-color: var(--color-background);
}

.bannerTop {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.languageToggle {
  width: 100%;
  text-align: end;
}

.contentBranding {
  width: 100%;
  text-align: start;
  display: block;
  margin-bottom: 0.5rem;

  .contentBrandingImg {
    width: auto;
    height: auto;
    max-height: var(--max-client-logo-height);
    max-width: var(--max-client-logo-width);
  }
}

.contentTop {
  display: block;
  width: 100%;
  text-align: center;
}

.heroIconWrapper {
  margin: 0 auto;
  max-width: 100px;
  max-height: 100px;

  svg {
    width: 100%;
    height: auto;
    max-width: 100px;
    max-height: 100px;
  }
}

.title {
  font-size: $font-size-large;
  line-height: $line-height-large;
  letter-spacing: normal;
  margin: 0.5rem 0 0;
}

.otherInfo {
  font-size: $font-size-normal;
  line-height: $line-height-normal;
  margin: 0.5rem 0 1rem;
}

.logoWrapper {
  margin: 1rem auto 0;
  padding-top: 0.5rem;
  border-top: solid 1px var(--color-neutral-light-tr70);
  display: none;
  width: 140px;

  &.showLogo {
    display: block;
  }

  a {
    border: none;
  }

  svg {
    fill: var(--color-foreground);
  }
}

.logoLink {
  display: inline-block;

  &:focus {
    outline: $focus-indicator;
    outline-offset: $focus-indicator-offset;
  }
}

.burningWrapper {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

@media all and (max-width: 550px) {
  .content {
    width: 100%;
    max-width: $max-content-width;
    box-sizing: border-box;
    margin: 0.25rem auto 4rem;
    // keep box for now
    /* padding: 0;
    border: none;
    box-shadow: none;
    background-color: transparent; */
  }
}
