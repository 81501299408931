@import "styles/variables.scss";
.dots_wrapper {
  width: 100%;
  box-sizing: border-box;
}

.not_ready,
.not_ready:hover,
.not_ready:active {
  opacity: 0.35;
}

.inline_dots {
  display: inline-block;
  text-align: start;
}

.inline_dots span {
  display: inline-block;
  margin-right: 5px;
  width: 10px;
  height: 10px;
  background-color: var(--color-primary);
  border-radius: 50%;
  opacity: 1;
}

.inline_dots.animate span {
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  opacity: 0.1;
}

.inline_loading {
  display: none;
}

.inline_dots_light .inline_dots span {
  background-color: var(--color-background);
}

.inline_dots span:nth-child(2) {
  animation-delay: 0.333s;
}

.inline_dots span:nth-child(3) {
  animation-delay: 0.667s;
}

@keyframes pulse {
  0% {
    opacity: 0.1;
    transform: scale(1);
  }

  50% {
    opacity: 1;
    transform: scale(1.25);
  }

  100% {
    opacity: 0.1;
    transform: scale(1);
  }
}

@media (forced-colors: active) {
  .inline_dots {
    display: none;
  }
  .inline_loading {
    display: inline-block;
  }
}
