@import "styles/variables.scss";
.contentsWrapper {
  width: 100%;
  text-align: start;
}

.contentsTitle {
  font-weight: 500;
  font-size: $font-size-normal-plus;
  line-height: $line-height-large;
  padding: 0;
  margin: 0;
}

.contents {
  margin-top: 0.25rem;
  font-weight: 400;
  font-size: $font-size-normal;
  line-height: $line-height-normal;
}
