@import "styles/variables.scss";
.alertMessage {
  position: relative;
  width: 100%;
  display: flex;
  background-color: var(--info-base-color);
  color: var(--messages-fill-color);
  padding: 0.75rem;
  font-size: $font-size-normal;
  line-height: $line-height-normal;
  border-radius: 3px;

  &.success {
    background-color: var(--success-base-color);
  }
  &.warning {
    background-color: var(--warning-base-color);
  }
  &.error {
    background-color: var(--error-base-color);
  }
  &.transparent {
    background-color: transparent;
  }
}

.boxIcon {
  width: 28px;
  text-align: start;
  svg {
    width: 18px;
    height: 18px;
    fill: var(--messages-fill-color);
  }
}

.messageContent {
  width: 100%;
  &.narrower {
    width: calc(100% - 28px);
  }
}

.closeButton {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  width: 24px;
  height: 24px;
  padding: 0.25rem;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
    fill: var(--messages-fill-color);
  }
}
