@import "styles/variables.scss";
.optionsSelectorOuter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selectLabel {
  display: block;
  font-size: $font-size-small;
  line-height: $line-height-small;
}

.selectWrapper {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: $font-size-small;
  width: 100%;
  height: 30px;
  min-width: 16ch;
  max-width: 30ch;
  background-color: transparent;
  transition: background-color $animation-time;

  &:focus,
  &:hover {
    background-color: var(--link-btn-base-color-tr10);
  }
}

.selectWrapper:has(select:focus),
.selectWrapper:has(select:hover) {
  background-color: var(--link-btn-base-color-tr10);
}

.selectWrapper select {
  // Reset default styles
  display: block;
  position: absolute;
  inset: 0;
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;

  &::-ms-expand {
    display: none;
  }

  * {
    background-color: var(--color-background);
    color: var(--color-foreground);
  }
}

.selectWrapper .select {
  padding: 2px 24px 2px 4px;
  border-radius: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--link-btn-base-color);

  &.withIcon {
    padding-left: 32px;
  }
}

.imageWrapper {
  display: block;
  position: absolute;

  height: 24px;
  width: 24px;
  svg {
    width: 18px;
    height: 18px;
    fill: var(--link-btn-base-color);
  }
}

.iconWrapper {
  top: 2px;
  left: 4px;
}

.arrowWrapper {
  height: 30px;
  top: 2px;
  right: 0px;
}

.option {
  max-width: 50px;
}

.goBtn {
  margin-left: 4px;
}

/* *** Stackable icons *** */
@media screen and (max-width: 800px) {
  .optionsSelectorOuter.stackable {
    text-align: center;

    .iconWrapper {
      position: absolute;
      top: 0.25rem;
      left: 50%;
      transform: translateX(-50%);
      height: 38px;
      width: 32px;

      svg {
        width: 28px;
        height: 28px;
      }
    }

    .selectWrapper {
      height: auto;
      min-width: 0;
      max-width: none;
      width: 80px;
      height: 64px;
      border-radius: 0.25rem;
    }

    .selectWrapper .select {
      position: static;
      display: inline-flex;
      padding: 44px 0.5rem 0.25rem;
      align-items: flex-end;
      font-size: $font-size-normal;
      line-height: $line-height-small;
      text-align: center;
      height: 100%;
      width: 100%;
      cursor: pointer;

      &.withIcon {
        padding-left: 0.5rem;
      }
    }

    .arrowWrapper {
      display: none;
    }
  }
}
