@import "styles/variables.scss";
//https://codepen.io/P1N2O/pen/pyBNzX
.app {
  width: 100%;
  height: 100%;
  min-height: 50vh;
  background: linear-gradient(
    -45deg,
    var(--color-background),
    var(--color-primary),
    var(--color-foreground)
  );
  background-size: 400% 400%;
  opacity: 0.3;
  border-radius: 4px;
  &.animate {
    animation: gradient 5s ease infinite;
  }
}

.highContrastLoading {
  display: none;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (forced-colors: active) {
  .app {
    padding: 1rem;
    border: 2px dashed $color-forced-light;
  }
  .highContrastLoading {
    display: block;
  }
}

@media (forced-colors: active) and (prefers-color-scheme: light) {
  .app {
    border-color: $color-forced-dark;
  }
}
